<template>
    <div class="complete-page">
        <div class="max-width">
            <div class="text-center mt-5">
                <img
                        width="80%"
                        :src="require('@/assets/images/complete.png')"
                        alt=""
                />
                <p class="font-24 font-600 darktext--text mt-4">
                    {{ $t('confirm') }}
                </p>
                <!-- btn -->
                <div
                        class="white radius-10 py-3 d-flex align-center justify-center cursor-pointer hover-btn-outlined mx-auto"
                        style="width: 180px"
                        @click="$router.push('/')"
                >
          <span class="font-700 font-18 register-primary--text"
          >{{ $t('global.GoToHome') }}</span
          >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.complete-page {
  padding: 80px 20px;
}
</style>
